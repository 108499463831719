.chat-loading {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  height: max-content;
  text-align: center;
}
.live-chat-comp {
  width: 100%;
  height: 100%;
}
#frame {
  width: 100%;
  height: 82vh;
  height: 100%;
  // min-height: 1000px;
}

#frame .content {
  float: right;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}
.chat-message-input-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .message-field {
    flex-grow: 1;
  }
  .message-button {
  }
}


.not-found-center {
  position: relative;
  min-height: 400px;
}
.not-found-center h3,
.not-found-center h5 {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  top: 0;
  height: fit-content;
  width: fit-content;
}


@media screen and (max-width: 735px) {
  // #frame .content {
  //   width: calc(100% - 58px);
  //   min-width: 300px !important;
  // }
}

#frame .content .contact-profile {
  width: 100%;
  height: 60px;
  line-height: 60px;
  background: #f5f5f5;
}
#frame .content .contact-profile img {
  width: 40px;
  border-radius: 50%;
  float: left;
  margin: 9px 12px 0 9px;
}
#frame .content .contact-profile p {
  float: left;
}
#frame .content .contact-profile .social-media {
  float: right;
}
#frame .content .contact-profile .social-media i {
  margin-left: 14px;
  cursor: pointer;
}
#frame .content .contact-profile .social-media i:nth-last-child(1) {
  margin-right: 20px;
}
#frame .content .contact-profile .social-media i:hover {
  color: #435f7a;
}
#frame .content .messages {
  // height: 586px;
  height: 100%;
  padding-bottom: 75px;
  // padding-bottom: 30px;

  // min-height: calc(100% - 93px);
  // max-height: calc(100% - 93px);
  // min-height: calc(100% - 60px);
  // max-height: 480px;
  // overflow: scroll;
  // overflow-y: scroll;
  // overflow-x: hidden;
}
#frame .content .messages {
  
  &.recorded-style {
    padding-bottom: 0px;
  }
}
#frame .content .messages > div {
  // padding-right: 15px;
  padding-right: 5px;
}

#frame .content .messages > div::-webkit-scrollbar {
  width: 10px;
}

#frame .content .messages > div::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #d3d3d3 !important;
  border-radius: 10px;
}

#frame .content .messages > div::-webkit-scrollbar-thumb,
#frame .content .messages > div::-webkit-scrollbar-thumb:hover {
  background: #000080 !important;
  border-radius: 10px;
}

#frame .content .messages > div + button {
  bottom: 75px;
  // bottom: 35px;
  // right: 0px;
  background-color: transparent;
  background-image: url(../images/chat-arrow.png);
  background-repeat: no-repeat;
  background-position: center center;
  z-index: 100;
}
#frame .content .messages.recoreded-scroll > div + button {
  // bottom: 0px;
}
#frame .content .messages > div:first-child {
  // overflow: hidden;
}
// @media screen and (max-width: 735px) {
//   #frame .content .messages {
//     max-height: calc(100% - 105px);
//   }
// }
#frame .content .messages::-webkit-scrollbar {
  width: 8px;
  background: transparent;
}
#frame .content .messages::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
}
#frame .content .messages ul li {
  // display: inline-block;
  display: flex;
  clear: both;
  // float: left;
  margin: 15px 0px;
  width: 100%;
  font-size: 0.9em;
}
.message-ul {
  padding: 0px;
  margin: 0px;
}
.message-ul:focus-visible {
  outline: -webkit-focus-ring-color auto 0px;
}
#frame .content .messages .msg-date {
  text-align: right;
  position: relative;
  //  bottom: -10px;
}
#frame .content .messages ul li {
  padding-right: 5px;
}
.replies .message-box-main {
  // float: right;
}
#frame .content .messages .msg-content {
  // display: inline-grid;
  margin: 0;
  display: flex;
  gap: 10px;
}
#frame .content .messages .user-name {
  font-weight: 700;
  font-family: 'Nunito Sans', sans-serif !important;
  font-size: 14px;
}

#frame .content .messages ul li:nth-last-child(1) {
  margin-bottom: 20px;
}
#frame .content .messages ul li.sent {
}
#frame .content .messages ul li.replies {
  justify-content: end;
  flex-direction: row-reverse;
}
#frame .content .messages ul li.sent img {
  margin: 6px 8px 0 0;
}
#frame .content .messages ul li.sent p {
  background: #f5f5f5;
  color: #0e0e0e;
}
#frame .content .messages ul li.replies img {
  float: right;
  margin: 6px 0 0 8px;
}
#frame .content .messages ul li.replies p {
  background: #0000801a;
  float: right;
  word-break: break-word;
}
#frame .content .messages ul li.replies .msg-date {
  float: right;
}
#frame .content .messages ul li img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  // float: left;
  border: 1px solid #ebebeb;
}

#frame .content .messages ul li p {
  display: inline-block;
  padding: 10px 15px;
  border-radius: 7px;
  max-width: 205px;
  // line-height: 130%;
}
.sent {
  .message-box-main {
    // float: left;
  }
}

@media screen and (min-width: 735px) {
  #frame .content .messages ul li p {
    max-width: 300px;
  }
}
#frame .content .message-input {
  position: absolute;
  bottom: 0;
  width: 100%;
  // z-index: 99;
  z-index: 19;
}
#frame .content .message-input .wrap {
  position: relative;
}
#frame .content .message-input .wrap input {
  font-family: 'proxima-nova', 'Source Sans Pro', sans-serif;
  float: left;
  border: none;
  // width: 82%;
  padding: 12px 5px;
  color: #32465a;
  border: 1px solid #c1c1c1;
  border-radius: 5px 0 0 5px;
}
@media screen and (max-width: 735px) {
  #frame .content .message-input .wrap input {
    padding: 15px 32px 16px 8px;
  }
}
#frame .content .message-input .wrap input:focus {
  outline: none;
}
#frame .content .message-input .wrap .attachment {
  position: absolute;
  right: 60px;
  z-index: 4;
  margin-top: 10px;
  font-size: 1.1em;
  color: #435f7a;
  opacity: 0.5;
  cursor: pointer;
}
@media screen and (max-width: 735px) {
  #frame .content .message-input .wrap .attachment {
    margin-top: 17px;
    right: 65px;
  }
}
#frame .content .message-input .wrap .attachment:hover {
  opacity: 1;
}
#frame .content .message-input .wrap button {
  float: right;
  border: none;
  width: 50px;
  padding: 12px 0;
  cursor: pointer;
  background: #000080;
  color: #f5f5f5;
  border-radius: 0 5px 5px 0px;
}

@media screen and (max-width: 735px) {
  #frame .content .message-input .wrap button {
    padding: 16px 0;
  }
}
#frame .content .message-input .wrap button:hover {
  background: #000000;
}
#frame .content .message-input .wrap button:focus {
  outline: none;
}

@media only screen and (max-width: 1280px) {
  #frame .content .messages {
    // height: 385px;
  }
}
@media only screen and (max-width: 991px) {

  .not-found-center {
    min-height: 300px;
  }

  

  #frame .content .messages {
    height: 385px;
  }
  .tabs-control-room-main .tabs-control-room.chat-tab-data .ant-tabs-content {
    display: inline-block;
  }
  #frame .content .messages {
    // height: 385px;
    padding-bottom: 60px;
  }
  #frame .content .messages > div + button {
    bottom: 60px;
  }
}


.chat-drowpdown .ant-dropdown-button .ant-dropdown-trigger {
  min-height: 0px !important;
  padding: 0px !important;
  min-width: 0px !important;
  height: 22px;
  width: 22px;
}
.chat-drowpdown .ant-dropdown-button .ant-dropdown-trigger span svg {
  transform: rotate(90deg);
}
.payment-box-white {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 2px rgba(0,0,0,.26);
  margin-bottom: 30px;
  padding: 25px 30px;
  .white-box {
    h4 {
      color: #252525;
      font-size: 22px;
      letter-spacing: 0.5px;
      font-weight: 700 !important;
      margin-bottom: 5px;
      padding: 0;
    }
  }
}

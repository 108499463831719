.inventory-image .ant-image-mask-info {
  display: none;
}

.product-detail .product-items {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
}



.product-detail .product-data {
  font-weight: 700;
  width: 150px;
}
.product-detail .product-desc {
  text-align: right;
  width: 100%;
}

.product-detail .ant-carousel .slick-dots {
  bottom: -21px;
}
.product-detail .ant-carousel .slick-dots li button {
  background: #000;
  height: 5px;
}
.product-detail .ant-carousel .slick-dots li.slick-active button {
  background: gray;
}
.product-detail .ant-tag {
  white-space: normal;
}


@media only screen and (max-width: 767px) {
.product-detail .items-column {
  margin-top: 20px;
}
}
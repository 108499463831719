.fee-structure .fee-form {
  width: 100%;
}
.fee-structure .fee-form .ant-input-number-input {
  height: 45px !important;
}
.fee-structure .fee-form .ant-input-number-group-addon {
  border: 1px solid #000000;
  border-right: 0;
}

.fee-structure .fee-label {
  display: flex;
  align-items: center;
}
.fee-structure .ant-form-item {
  margin: 0;
}
.bioshop-container-main {
  /* max-width: 680px; */
  /* margin: 0 auto; */
  min-height: 650px;
  font-family: "Nunito Sans", sans-serif !important;
  background-color: #ffffff;
  padding: 25px 25px;
  border-radius: 5px;
  box-shadow: 0 0 2px rgb(0 0 0 / 26%);

}

.bioshop-fixed {
  /* height: 90%;
  -webkit-overflow-scrolling: touch;
  overflow-y: scroll;
  position: relative; */
  /* max-height: 860px; */
  /* overflow-y: auto;
  overflow-x: hidden; */
}

.bioshop-fixed::-webkit-scrollbar {
  /* display: none; */
}

.bioshop-body {
  position: relative;
}

.post-box {
  /* height: 664px; */
  overflow: auto;
}

.post-box::-webkit-scrollbar {
  width: 10px;
}

.post-box::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #d3d3d3 !important;
  border-radius: 10px;
}

.post-box::-webkit-scrollbar-thumb,
.post-box::-webkit-scrollbar-thumb:hover {
  background: #000080 !important;
  border-radius: 10px;
}

.bioshop-fixed .af-rm-mn {
  /* max-height: 630px; */
  /* height: 90%; */
  overflow-y: auto;
  overflow-x: hidden;
}

.bioshop-fixed .af-rm-mn::-webkit-scrollbar {
  display: none;
}

.header-fixed {
  left: 0px;
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -o-sticky;
  position: -ms-sticky;
  position: sticky;
  top: 0px;
  width: 100%;
  z-index: 1000;
  background: #fff;
}

.header-box {
  display: flex;
  align-items: center;
}

.header-box span {
  display: block;
  margin-top: 10px;
  font-weight: 700;
  font-size: 15px;
}

.header-box .heading {}

.header-box .new-heading {
  position: relative;
  top: -15px;
  left: 78px;
  z-index: 99;
  font-weight: 700;
}

.header-box .header-image {
  margin-right: 1rem;
  margin-top: 1rem;
  border: solid 1px #ccc;
}

.header-box .shop-btn {
  color: red;
  text-decoration: underline;
  font-family: "Nunito Sans", sans-serif;
}

img {
  object-fit: cover;
}

/****************main-carousel**************/
.main-carousel {
  /* margin-top: 20px; */
}

/*************bio-profile********************/
.bio-profile {
  text-align: center;
  margin-top: 60px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
}

.bio-profile .profile-heading {
  font-size: 22px;
  margin: 10px 0px;
}

.bio-profile .profile-bio {
  margin: 0 auto;
  width: 60%;
}

/*************biolink***************/
.biolink-area .link-box {
  background: #185887;
  border: 1px solid #185887;
  margin: 13px auto;
  padding: 12px 0;
  border-radius: 10px;
  font-size: 1rem;
  color: #fff;
  letter-spacing: 1px;
}

.biolink-area .link-box:hover {
  background: #fff;
  color: #000;
  border: 1px solid #185887;
}

/************Image Modal****************/
.bio-image-modal .modal-dialog {
  max-width: 400px;
  position: relative;
  left: 20%;
}

.bio-image-modal .modal-title {
  line-height: 14px;
  font-size: 14px;
  text-transform: uppercase;
}

.bio_shop_header {
  /* display: flex;
  justify-content: space-between; */
  align-items: center;
  /* gap: 15px; */
}

.bio_shop_header .bio_shop_header_left {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  margin-bottom: 20px;
}

.bio_shop_header .bio_shop_header_left .brand_logo {
  border: 1px solid #ccc;
  border-radius: 50px;

}

.bio_shop_header .bio_shop_header_left .brand_logo img {
  height: 76px;
  width: 76px;
  border-radius: 50px;
}

.bio_shop_header .bio_shop_header_left .current-brand-name {
  margin-left: 5px;
  color: #000;
  font-family: Nunito Sans, sans-serif !important;
  font-size: 1rem;
  font-weight: 800;
  margin-left: 15px;
  text-transform: uppercase;
}



.bio_shop_header .bio_shop_copy_link {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid black;
  margin: 0 10px 20px 8px;
}

.bio_shop_header .bio_shop_copy_link .link {
  padding: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.bio_shop_header .bio_shop_copy_link .link a {
  color: black;
}

.bio_shop_header .bio_shop_copy_link .link a:hover {
  text-decoration: underline;
}

.bio_shop_header .bio_shop_copy_link .copyBtn {
  /* margin-left: 60px; */
  border-left: 1px solid black;
  padding: 10px;
  cursor: pointer;
}
.bio_shop_header .bio_shop_copy_link .copyBtn{
  border-left: 1px solid black;
  background: #000080;
  color: white;
  padding: 10px;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  
}
.bio_shop_header .bio_shop_copy_link .copyBtn:hover {
  background: #000000;
  color: white;
}

.bio_shop_header .bio_shop_copy_link .playback-txt2 {
  font-size: 13px;
  border-right: 1px solid black;
  padding: 0 10px 0 0;
}


.bio_shop_copy_link_events_main {
  position: absolute;
  left: 32%;
  bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}

.bio_shop_copy_link_events {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid black;
  /* margin: 0 10px 0px 10px; */

}

.bio_shop_copy_link_events .link {
  padding: 10px;

  display: flex;
  align-items: center;
  gap: 10px;
}

.bio_shop_copy_link_events_main .playback-txt {
  font-size: 13px;
}

.bio_shop_copy_link_events_main .playback-txt2 {
  font-size: 13px;
  border-right: 1px solid black;
  padding: 0 10px 0 0;
}

.bio_shop_copy_link_events .link a {
  color: black;
  font-size: 13px;
}

.bio_shop_copy_link_events .link a:hover {
  text-decoration: underline;
}

.bio_shop_copy_link_events .copyBtn {
  border-left: 1px solid black;
  background: #000080;
  color: white;
  padding: 10px;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  
}
.bio_shop_copy_link_events .copyBtn:hover {
  background: #000000;
  color: white;
}



.bio-image-modal .modal-header {
  padding: 0;
  padding-bottom: 5px;
  margin-bottom: 5px;
  border: none;
}

.bio-image-modal .modal-header .close {
  padding: 9px 1rem;
}

.bio-image-modal .modal-content {
  padding: 1rem 1rem !important;
}

.bio-image-modal .inner-image-box {
  /* height: 140px; */
  position: relative;
  margin-top: 32px;
}

.bio-image-modal .inner-image-box .space-grid-right {
  padding-right: 7px;
}

.bio-image-modal .inner-image-box .space-grid-left {
  padding-left: 5px;
  gap: 10px;
}

.bio-image-modal .inner-image-box img {
  border: solid 1px #ccc;
  padding: 2px;
  border-radius: 2px;
  width: 100%;
}

.bio-image-modal .inner-image-box .image_link {
  height: 100%;
}

.bio-image-modal .image-inner-media {
  width: 100%;
  border-radius: 0.25rem !important;
}

.bio-image-modal .image-inner-box .image_link {
  width: 100%;
}

.bio-image-modal .inner-image-box .image_num {
  position: absolute;
  background: #fff;
  width: 16px;
  height: 16px;
  text-align: center;
  bottom: 8px;
  color: #000;
  line-height: 15px;
  left: 8px;
  border-radius: 50%;
  font-size: 10px;
  font-weight: bold;
  cursor: pointer;
}

.bio-image-modal .image-inner-box {
  position: relative;
}

.bio-image-modal .tag-div-main {
  background-color: white;
  border-radius: 50%;
  color: #000;
  font-size: 11px;
  height: 20px;
  left: 45%;
  line-height: 20px;
  position: absolute;
  width: 20px;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
}

.related-images {
  margin-top: 20px;
  margin-left: -8px;
  margin-right: -8px;
}

.related-images img {
  height: 94px !important;
}

.related-images .col-md-4:first-child {
  padding-right: 15px;
}

.related-images .col-md-4:last-child {
  padding-left: 15px;
}

.related-images .col-md-4 {
  padding-left: 15px;
  padding-right: 15px;
}

.related-images {
  margin-top: 10px;
}

.bio-image-modal .inner-image-box .prod-name {
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  text-align: left;
}

.bio-image-modal .inner-image-box .prod-sku {
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-weight: 500;
  font-size: 14px;
}

.bio-image-modal .inner-image-box .prod-sku .prod-amount {
  color: green;
  display: flex;
  padding-right: 5px;
}

.bio-image-modal .inner-image-box .prod-sku .prod-discount {
  color: green;
  margin-left: 12px;
}

.bio-image-modal .inner-image-box .prod-shop {
  /* margin-top: 10px; */
  /* position: absolute;
  bottom: 0; */
  /* width: 100%; */
  margin-top: 10px;
  width: 32%;
}

.bio-image-modal .inner-image-box .prod-shop .shop-button {
  background: #000080;
  border-color: #000080;
  font-size: 11px;
  height: 100%;
  padding: 4px 0px;
  color: #fff;
}

.bio-image-modal .inner-image-box .prod-shop .shop13 {
  padding: 12px 0;
}

.bio-image-modal .inner-image-box .prod-shop .fa {
  display: block !important;
}

.bio-image-modal .inner-image-box .prod-footer {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.bio-image-modal .react-multiple-carousel__arrow {
  min-height: auto !important;
  top: 6px;
}

.bio-image-modal .react-multiple-carousel__arrow--left {
  /* right: -400px !important;
} */
  left: -5px !important;
}

.bio-image-modal .react-multiple-carousel__arrow--right {
  right: -5px !important;
}

.react-multiple-carousel__arrow::before {
  color: #000080 !important;
  font-size: 20px !important;
  font-weight: bold;
}

.main-carousel .react-multiple-carousel__arrow::before {
  top: -7px;
}

.active-circle {
  background-color: red !important;
  color: #fff !important;
}

@media (max-width: 767px) {
  .bio-image-modal .inner-image-box .prod-name {
    font-size: 14px;
  }

  .bio-image-modal .inner-image-box .prod-sku {
    font-size: 14px;
  }

  .bio-image-modal .inner-image-box {
    margin-top: 10px;
  }

  .bioshop-fixed {
    display: none;
  }

  .right-bar {
    position: absolute !important;
    top: 92px !important;
    left: 12px !important;
    max-width: 96% !important;
  }

  .linkin-bio .image-edit-box .image-wrapper {
    display: block !important;
  }

  .linked_edit_box {
    /* position: relative; */
  }

  .mobile-edit-linked {
    position: relative;
  }
}

@media (max-width: 576px) {
  .bio-image-modal .modal-dialog {
    width: auto;
    max-width: 100%;
  }

  .bio-image-modal .inner-image-box .prod-shop .shop-button {
    font-size: 9px;
  }
}

.iframe-bio-image .modal-dialog {
  max-width: 400px;
}

@media (max-width: 1000px) {
  .iframe-bio-image .modal-dialog {
    max-width: 320px;
    margin: 0 auto;
  }
}

.your-copy-link {
  display: flex;
  justify-content: space-between;
  /* // padding: 5px; */
  border: 1px solid black;
  /* // border-radius: 1em; */
}

.your-copy-link .item-a {
  display: inline-block;
  padding: 10px;
  cursor: default;
  overflow: hidden;
  height: 28px;
  word-break: break-all;
}

.your-copy-link .item-a a {
  color: black;
  /* font-weight: bold; */
}

.your-copy-link .item-b {
  padding: 10px;
  border-left: 1px solid black;
  cursor: pointer;
}

.bioshop-modal .your-copy-link .item-a {
  display: inline-block;
  padding: 10px;
  cursor: default;
  overflow: hidden;
  height: 28px;
  word-break: break-all;
}

.bioshop-modal .your-copy-link .item-b {
  padding: 10px;
}

.copy-error {
  position: relative;
  color: green;
  text-align: right;
  top: 4px;
  font-size: 13px;
}

.your-copy-link .copyied {
  color: #fff;
  background-color: #198754;
}

.bio-share-modal {
  position: relative;
  left: 20%;
  top: 10%;
}

.bio-share-modal .modal-body {
  background: transparent;
}

/* Responsive Media Queries */
@media only screen and (min-width: 1024px) and (max-width: 1660px) {
  /* .bio-image-modal .modal-dialog {
    max-width: 400px;
  } */
}

@media only screen and (min-width: 1201px) and (max-width: 1365px) {
  .col-lg-8.bioshop-link-width {
    /* flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%; */
  }
}




@media only screen and (max-width: 1700px) {
  .bio_shop_copy_link_events_main {
    left: 38%;
  }
}
@media only screen and (max-width: 1440px) {
  .bio_shop_copy_link_events_main {
    left: 40%;
  }
}

@media only screen and (max-width: 1366px) {
  .bio_shop_copy_link_events_main {
    position: static;
  }
}

@media only screen and (max-width: 1024px) {
  .bio_shop_header {
    display: block;
  }
}

@media only screen and (max-width: 767px) {
  .bio_shop_copy_link_events_main .playback-txt2 {
    font-size: 12px;
  }
  .bio_shop_copy_link_events .link a {
    font-size: 12px;
}
  .left-column.mob-full {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }

  .bio_shop_header {
    display: none !important;
  }
}

@media only screen and (min-width: 1641px) {
  .col-lg-8.bioshop-link-width {
    /* display: flex;
    justify-content: center; */
  }

  .col-lg-8.bioshop-link-width {
    /* flex: 0 0 33.3333333333%;
  max-width: 33.3333333333% */
  }

  .bio_shop_header .bio_shop_copy_link {
    /* margin:0 10px 20px 10px; */
  }

}

@media only screen and (max-width: 1640px) {
  .col-lg-8.bioshop-link-width {
    /* display: flex;
    justify-content: end; */
  }

  .col-lg-8.bioshop-link-width {
    /* flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%; */
  }

  .bio_shop_header .bio_shop_copy_link {
    /* margin:0 0px 20px 10px; */
  }

}
.single-camera-enabled {
  position: relative;
  margin: auto;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .single-camera-enabled-inner {
    display: flex;
    justify-content: center;
    gap: 10px;
    align-items: center;
    flex-direction: column;
    .camera-icon {
      font-size: 25px;
    }
    h3 {
      font-size: 18px;
    }
  }
}

.single-control-main {
  padding: 10px 10px;
}

.single-stream-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  .single-stream-main {
    display: flex;
    .single-stream-canvas-left {
      width: 85%;
      .single-stream-canvas {
        border: 5px solid #000;
        // background-color: #ccc;
        height: 530px;
        canvas {
          max-height: 520px;
          width: 100%;
          // object-fit: contain;
          -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
        }
      }
    }
    .single-stream-canvas-right {
      width: 15%;
    }
  }
}

@media only screen and (max-width: 1600px) {
  .single-stream-wrapper {
    .single-stream-main {
      .single-stream-canvas-left {
        .single-stream-canvas {
          height: 421px;
          canvas {
            max-height: 411px;
            // object-fit: contain;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 1440px) {
  .single-stream-wrapper {
    .single-stream-main {
      .single-stream-canvas-left {
        .single-stream-canvas {
          height: 369px;
          canvas {
            max-height: 359px;
            // object-fit: contain;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 1400px) {
  .single-stream-wrapper {
    .single-stream-main {
      .single-stream-canvas-left {
        .single-stream-canvas {
          height: 356px;
          canvas {
            max-height: 346px;
            // object-fit: contain;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 1366px) {
  .single-stream-wrapper {
    .single-stream-main {
      .single-stream-canvas-left {
        .single-stream-canvas {
          height: 345px;
          canvas {
            max-height: 335px;
            // object-fit: contain;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 1360px) {
  .single-stream-wrapper {
    .single-stream-main {
      .single-stream-canvas-left {
        .single-stream-canvas {
          height: 343px;
          canvas {
            max-height: 333px;
            // object-fit: contain;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 1280px) {
  .single-stream-wrapper {
    .single-stream-main {
      .single-stream-canvas-left {
        .single-stream-canvas {
          height: 313px;
          canvas {
            max-height: 303px;
            // object-fit: contain;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 1152px) {
  .single-stream-wrapper {
    .single-stream-main {
      .single-stream-canvas-left {
        .single-stream-canvas {
          height: 357px;
          canvas {
            max-height: 347px;
            // object-fit: contain;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 1024px) {
  .single-stream-wrapper {
    .single-stream-main {
      .single-stream-canvas-left {
        .single-stream-canvas {
          height: 313px;
          canvas {
            max-height: 303px;
            // object-fit: contain;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 991px) {
  .single-stream-wrapper {
    .single-stream-main {
      .single-stream-canvas-left {
        .single-stream-canvas {
          height: 444px;
          canvas {
            max-height: 334px;
            // object-fit: contain;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 767px) {
  .single-stream-wrapper {
    .single-stream-main {
      .single-stream-canvas-left {
        .single-stream-canvas {
          height: 350px;
          canvas {
            max-height: 340px;
            // object-fit: contain;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 576px) {
  .single-stream-wrapper {
    .single-stream-main {
      .single-stream-canvas-left {
        .single-stream-canvas {
          height: auto;
          canvas {
            max-height: 340px;
            // object-fit: contain;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .single-stream-wrapper {
    .single-stream-main {
      .single-stream-canvas-left {
        width: 100%;
      }
      .single-stream-canvas-right {
        display: none;
      }
    }
  }
  .single-control-main {
    .mob-control {
      margin-bottom: 10px;
    }
    .select-style {
      font-size: 12px;
    }
  }
}
